
import { mapGetters, mapActions } from "vuex";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { FormatDate, FormatHour, AvailableHours } from "@/shared/helpers/dateHelpers";
import { TeachingHoursTypes, TeachingHoursSubTypes } from "@/shared/utils/select";
import { defineComponent } from "@vue/composition-api";

interface ComponentData {
  valid: boolean;
}

export default defineComponent({
  name: "TheHoursDetailsModal",
  components: { BaseModalForm },
  data: function (): ComponentData {
    return {
      valid: true,
    };
  },
  computed: {
    ...mapGetters("course", ["getCourse"]),
    ...mapGetters("hoc", ["getId", "getLoading"]),
    translatedTypes() {
      let translatedSelect: any[] = [];
      TeachingHoursTypes.forEach((item) => {
        const translatedObject = {
          text: item.text,
          value: item.value,
        };
        translatedSelect.push(translatedObject);
      });
      return translatedSelect;
    },
    translatedSubTypes() {
      let translatedSelect: any[] = [];
      TeachingHoursSubTypes.forEach((item) => {
        const translatedObject = {
          text: item.text,
          value: item.value,
        };
        translatedSelect.push(translatedObject);
      });
      return translatedSelect;
    },
    hour() {
      const id: any = this.getId;
      if (!this.getLoading && this.getId !== null) {
        const date = FormatDate(this.getCourse.plan.schedules[id].start);
        const startHour = FormatHour(this.getCourse.plan.schedules[id].start);
        const endHour = FormatHour(this.getCourse.plan.schedules[id].end);
        const hours = startHour + " - " + endHour;
        const registeredHours = undefined;
        const approvedHours = undefined;

        let hourItem = {
          date: date,
          hours: hours,
          pdId: 300,
          pdName: "Undervisningstimer",
          pdSubTypeId: 301,
          pdSubTypeName: "Undervisningstimer 1-2",
          title: (this as any).getCourse.plan.schedules[id].title,
          status: "Ny",
          registeredHours: registeredHours,
          approvedHours: approvedHours,
          comment: "",
        };
        return hourItem;
      }
      return null;
    },
    items() {
      return AvailableHours(this.getCourse, this.getId);
    },
    headline() {
      return "Fyll ut timer for dato" + ": " + (this as any).hour.date;
    },
  },
  methods: {
    ...mapActions("hours", ["fetchHour", "updateHour"]),
    setTeachingType(value: any) {
      this.type = value;
    },
    setTeachingSubType(value: any) {
      this.underType = value;
    },
    onSubmit() {
      if ((this as any).$refs.connectForm.validate()) {
        this.updateHour(this.id);
      }
      this.$emit("close");
    },
  },
});
