var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('BaseBreadCrumbs'), _vm.hourItems.length <= 0 ? _c('div', {
    staticClass: "title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" Ingen arbeidstimer funnet ")]) : _vm._e(), _vm.showHoursDetailsModal ? _c('BaseModal', [_c('TheHoursDetailsModal', {
    on: {
      "close": function ($event) {
        _vm.showHoursDetailsModal = false;
      }
    }
  })], 1) : _c('v-card', {
    attrs: {
      "elevation": 1
    }
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" Kurs arbeidstimer ")]), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    staticClass: "pt-0",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Søk",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.hourItems,
      "sort-by": "id",
      "search": _vm.search,
      "sort-desc": false,
      "data-cy": "courseHoursTable"
    },
    on: {
      "click:row": _vm.handleHour
    },
    scopedSlots: _vm._u([{
      key: "item.registeredHours",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-chip', {
          attrs: {
            "color": _vm.getColor(item.registeredHours),
            "dark": "",
            "outlined": ""
          }
        }, [item.registeredHours !== '' ? _c('span', [_vm._v(" " + _vm._s(item.registeredHours) + " ")]) : _c('span', [_vm._v(" Ikke utfylt ")])])];
      }
    }, {
      key: "item.approvedHours",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "color": _vm.getColor(item.approvedHours),
            "dark": "",
            "outlined": ""
          }
        }, [item.approvedHours !== '' ? _c('span', [_vm._v(" " + _vm._s(item.approvedHours) + " ")]) : _c('span', [_vm._v(" Ikke godkjent ")])])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }