var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.translatedTypes,
      "label": "Velg undervisningstime kategori"
    },
    on: {
      "change": _vm.setTeachingType
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.translatedSubTypes,
      "label": "Velg undervisningstime underkategori"
    },
    on: {
      "change": _vm.setTeachingSubType
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "sm": "3",
      "md": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.items,
      "label": "Antall av timer",
      "single-line": ""
    },
    model: {
      value: _vm.hour.registeredHours,
      callback: function ($$v) {
        _vm.$set(_vm.hour, "registeredHours", $$v);
      },
      expression: "hour.registeredHours"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "sm": "9",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Notat"
    },
    model: {
      value: _vm.hour.comment,
      callback: function ($$v) {
        _vm.$set(_vm.hour, "comment", $$v);
      },
      expression: "hour.comment"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }