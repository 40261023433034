export const LanguageItems = [
  {
    text: "settings.theme.norwegian",
    value: "no",
  },
  {
    text: "settings.theme.english",
    value: "en",
  },
];

export const TeachingHoursTypes = [
  {
    text: "hours.type.teaching",
    value: "Undervisningstimer",
  },
];

export const TeachingHoursSubTypes = [
  {
    text: "hours.subType.teaching",
    value: "Undervisningstimer",
  },
];
