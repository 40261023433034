
import { mapGetters, mapActions } from "vuex";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import { FormatDate, FormatHour } from "@/shared/helpers/dateHelpers";
import { CourseHoursTableColumns } from "@/shared/utils/tableHeaders";
import { FormatList } from "@/shared/helpers/filterHelpers";
import { defineComponent } from "@vue/composition-api";
/* Modals */
import TheHoursDetailsModal from "@/pages/hours/TheHoursDetailsModal.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";

interface ComponentData {
  search: string;
  showHoursDetailsModal: boolean;
}

export default defineComponent({
  name: "CourseHoursListPage",
  components: { BaseBreadCrumbs, BaseModal, TheHoursDetailsModal },
  data: function (): ComponentData {
    return {
      search: "",
      showHoursDetailsModal: false,
    };
  },
  computed: {
    ...mapGetters("course", ["getCourse"]),
    ...mapGetters("hours", ["getCourseHours"]),
    tableHeaders() {
      return FormatList(CourseHoursTableColumns, "text");
    },
    hourItems() {
      let hoursTable: any[] = [];
      if ((this as any).getCourse.plan) {
        (this as any).getCourse.plan.schedules.forEach((item: any, index: any) => {
          const date = FormatDate(item.start);
          const startHour = FormatHour(item.start);
          const endHour = FormatHour(item.end);
          const hours = startHour + " - " + endHour;
          const hourItem = {
            id: index,
            date: date,
            hours: hours,
            pdId: 300,
            pdName: "Undervisningstimer",
            pdSubTypeId: 301,
            pdSubTypeName: "Undervisningstimer 1-2",
            title: item.title,
            status: "Ny",
            registeredHours: "",
            approvedHours: "",
            comment: "",
          };
          hoursTable.push(hourItem);
        });
      }
      return hoursTable;
    },
  },
  methods: {
    ...mapActions("course", ["fetchCourse"]),
    ...mapActions("hours", ["fetchCourseHours"]),
    handleHour() {
      this.showHoursDetailsModal = true;
    },
    getColor(factor: any) {
      if (factor === 100) {
        return "green";
      }
      if (factor < 100 && factor > 0) {
        return "orange";
      }
      return "red";
    },
  },
  async created() {
    const courseId = this.$router.currentRoute.params.id;
    await (this as any).fetchCourse({ courseId: courseId });
    if ((!this as any).getCourseHours || (this as any).getCourseHours.length) {
      await (this as any).fetchCourseHours(courseId);
    }
  },
});
